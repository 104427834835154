@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

body {
  font-family: "halcom", sans-serif;
}

.select-wrapper {
  position: relative;
}

.select-wrapper::after {
  content: "\203A";
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%) rotate(90deg);
}

select {
  appearance: none;
  -webkit-appearance: none;
  padding: 7px 40px 7px 12px;
  border: 1px solid #e5e7eb;
}

.react-datepicker-wrapper {
  width: 100%;
}

@media screen {
  .print-only {
    display: none;
  }
}

body.has-printable-component {
  @media print {
    @page {
      size: letter;
    }

    & * {
      visibility: hidden;
      font-size: 8pt;
    }

    .print-only, .print-only * {
      visibility: visible;
    }

    .print-only {
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
    }

    .print-only > thead > tr > th {
      text-align: left;
      padding-bottom: 20px;
      font-size: 14pt;
    }

    .print-only table {
      border-collapse: collapse;
      width: 100%;
      table-layout: auto;
    }

    .print-only table table {
      width: 100%;
      max-width: 100%;
    }

    .print-only table table.fixed-layout {
      table-layout: fixed;
    }

    .print-only table table th,
    .print-only table table td {
      overflow: hidden;
      white-space: normal;
      word-wrap: break-word;
    }

    .print-only .header-title {
      text-align: left;
      font-size: 18px;
    }

    .print-only th {
      text-transform: uppercase;
    }

    .print-only table table th {
      text-align: left;
      padding: 8px;
    }

    .print-only table table td {
      border-top: 1px solid #D9D9D9;
      padding: 8px;
    }
  
    .print-only table table th:first-child, .print-only table table td:first-child {
      padding-left: 0;
    }

    .print-only table table th:last-child, .print-only table table td:last-child {
      padding-right: 0;
    }

    /* Prevent page breaks inside table rows */
    .print-only table table tr {
      page-break-inside: avoid;
      break-inside: avoid;
    }
  }  
}
